export enum FileTypeEnum {
  CSV = "text/csv",
  XLS = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
  ZIP = "application/zip",
}

export const downloadFile = (
  file: string,
  type: FileTypeEnum,
  name: string
): void => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  const blob = new Blob([file], { type: type });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
};
