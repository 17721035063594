import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface IDownloadPayoutBody {
  businessId: string;
  payoutId: string;
}

export class DownloadPayoutError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: DownloadPayout");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function downloadPayout(
  request: IDownloadPayoutBody
): Promise<string> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/statements-of-accounts/${request.payoutId}/download`,
      {
        responseType: "blob",
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new DownloadPayoutError(error);
  }

  return response.data;
}
